/**********/
/* Common */
/**********/
:root {
    --very-light-grey: #fdfdfd;
    --lighter-grey: #fafafa;
    --light-grey: #f1f1f1;
    --grey: #BEC3C7;
    --greyer: #777;
    --dark-grey: #333;
    --very-dark-grey: #222;

    --color-overlay: rgba(70, 70, 70, 0.8);

    --color-a: #5f0f4e;
    --color-a-light: #6C1C5B;
    --color-b: #675682;
    --color-b-light: #E3E0E8;
    --color-b-light2: #827498;
    --color-c: darkgreen;
    --color-c-light: #67977f;
    --color-error: #BA2D0B;
    --color-important: #BA2D0B;
    --color-light-error: #F2D8D2 ;

    --shadow-height: 15px;
    --shadow-color: rgba(0,0,0,0.3);

    --instance-name: "SHANOIR_INSTANCE_NAME";
    --instance-color: SHANOIR_INSTANCE_COLOR;
    --user-color:     SHANOIR_USER_COLOR;
}

html { height: 100%; }
body { margin: 0; background-color: var(--very-light-grey); min-height: 100%; }
  body.outsilde { background-color: var(--color-a); }
body, option, table, button, select, input, textarea { font-family: "Open Sans", Arial, sans-serif; font-size: 12px; color: var(--very-dark-black); }
h1 { font-size: 28px; }
h1, h2, h3, h4 { color: var(--color-a); }
h2 { margin: 0; font-size: 25px; }
hr { margin: 7px 0 !important; border-bottom: 1px solid var(--color-b-light) !important; border: 0; }
option:disabled { color: var(--greyer); }
ul { padding: 0; }
li { list-style: none; }
form { margin: 0; }
select:disabled { opacity: 0.5; }
a:hover { cursor: pointer; }
.bold { font-weight: bold; }


@-webkit-keyframes autofill {
  to {
      color: var(--dark-grey);
      background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

table.layout { border: none; border-collapse: collapse; }
  table.layout td { padding: 0;	vertical-align: top; }
  table.layout td + td { border-left: 3px dashed var(--light-grey); }

button { background-color: var(--color-b); border: 0px; border-radius: 2px; color: var(--very-light-grey); padding: 5px 10px; transition-property: transform, box-shadow; transition-duration: 0.3s; transition-timing-function: ease-in-out; }
  button:disabled { background-color: var(--grey); border-color: var(--grey); cursor: auto; }
  button:active,
  button:enabled:hover { cursor: pointer; color: var(--very-light-grey); background-color: var(--color-b-light2); box-shadow: none !important; transform: none !important; transition: none !important; }
button + button { margin-left: 5px; }
button.alt { background-color: var(--color-c); }
  button.alt:disabled { background-color: var(--grey); }
  button.alt:active,
  button.alt:enabled:hover { background-color: var(--color-c-light); }


input[type='date'] { width: 140px; }
.hidden { display: none !important; }
h2 + shanoir-table { margin-top: 20px; }

input, select, select-box, datepicker {
  border: none; border-bottom: 1px solid var(--grey); background-color: transparent; height: 19px; padding: 1px 0;
}
select-box.read-only { height: auto; }
input:focus, select:focus, select-box:focus, select-box:focus-within, datepicker:focus {
  outline: none; border-bottom: 2px inset var(--color-a) !important; padding-bottom: 0;
}
input[type="color"] { border: none !important; cursor: pointer; }

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
select-box.ng-invalid.ng-touched,
datepicker.ng-invalid.ng-touched { border-bottom: 2px solid var(--color-error); color: var(--color-error); padding-bottom: 0; }

textarea { outline: none !important; border: 1px solid var(--grey); border-radius: 2px; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;}
textarea:focus { border: 2px inset var(--color-a) !important; padding: 1px; }
textarea.ng-invalid.ng-touched { border: 2px solid var(--color-error); padding: 1px; }

a.disabled { pointer-events: none; cursor: default; }

form { color: var(--dark-grey); position: relative; }
  form *[readonly] { cursor: default; border: none !important; width: default;}
  form input[type='radio'] { vertical-align: top; height: 14px; }
  form input[type='radio'] + input[type='radio'] { margin-left: 20px; }
  form textarea { resize: vertical; }
  form button:not(.normal) { margin-top: 10px; margin-bottom: 10px; }
  form input:not([type]), form input[type='text'], form input[type='password'], form input[type='email'], form textarea, form select, form select-box { width: 220px; }
  form fieldset { border: 0; padding: 0; margin: 30px 0; display: block; min-width: 504px; }
  form fieldset.disabled { color: var(--grey); }
  form fieldset ~ fieldset { margin-right: 20px; }
  form fieldset.line { border-top: 1px solid var(--grey); padding-top: 11px; }
    form fieldset fieldset legend { padding: 0 0 5px; font-weight: normal; }
    form fieldset fieldset label { display: block; width: auto; }
    form fieldset fieldset label { margin-left: 183px; }
  form legend { padding: 0 10px 0 0; color: var(--color-a); font-size: 14px; margin: 10px 0; color: var(--color-c); width: 100%; }
  form li > label { display: inline-block; width: 250px; vertical-align: top; min-height: 20px; line-height: 20px; }
  form fieldset ol, form fieldset ul { margin: 0; padding: 0; }
  form fieldset li { list-style: none; padding: 3px 0; margin: 0; min-height: 22px; }
    form fieldset li input,
    form fieldset li select,
    form fieldset li button { height: 21px; }
    form li.info { margin: 4px 0 13px; background-color: var(--color-b-light); padding: 10px 15px; overflow: hidden; width: 440px; border-radius: 2px; }
      form li.info i { margin-right: 6px; color: var(--color-b); font-size: 16px; }
      form li.info b { font-weight: bold; color: var(--color-a); }
  form em { font-weight: bold; font-style: normal; color: var(--color-error); }
  form .header { padding-top: 10px; padding-bottom: 10px; }
  form div.header { font-size: 16px; }
  form .required-label:after{ content:" *"; }
  form .form-validation-alert { color: var(--color-error); width: 100%; overflow: hidden; width: 220px; }
  form .right-col { display: inline-block; min-width: 250px; line-height: 20px; vertical-align: top; }
  form .right-col label { text-align: left; display: block; }
  form .right-col button:not(.normal) { padding: 0 5px; margin: 0; background-color: transparent; border: 1px solid var(--grey); color: var(--very-dark-grey); height: 21px; }
    form .right-col button:not(.normal):active { background-color: var(--color-b-light); }
    form .right-col button:not(.normal):hover { background-color: inherit; color: inherit; }
    form .right-col button:not(.normal):disabled { color: var(--grey); }
    form .right-col button:not(.normal) img { height: 12px; vertical-align: sub; margin: 0px 5px 2px 0px; }
    form .right-col button:not(.normal) i { color: var(--color-a); }

  form.disabled::after { content: ""; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: var(--very-light-grey); opacity: 0.5; }

  /* Date picker style overriding */
  my-date-picker input.selection { height: 19px; font-size: 12px !important; font-weight: normal !important; color: var(--greyer) !important; font-family: "Open Sans", Arial, sans-serif !important; background-color: transparent !important; border-radius: 0 !important; }
  my-date-picker .selectiongroup { background-color: transparent !important; }
  my-date-picker .selector { border: 1px solid var(--light-grey) !important; box-shadow: 0 0 var(--shadow-height) 0px gray !important; }
  my-date-picker input.selection { text-align: left !important; padding: 0 !important; }
  my-date-picker button.btnpicker,
  my-date-picker button.btnclear { font-weight: normal !important; color: var(--greyer) !important; border: 0 !important; height: 10px; background-color: transparent !important; }
  /* form my-date-picker button.btnpicker:hover,
  form my-date-picker button.btnclear:hover { color: var(--color-a) !important; background-color: transparent !important; } */
    my-date-picker button.btnpicker span,
    my-date-picker button.btnclear span { font-size: 12px !important; }
  my-date-picker .icon-calendar { line-height: 17px !important; }
  my-date-picker .mydp { border: 0 !important; font-family: "Open Sans",Arial,sans-serif !important;}
  my-date-picker .mydp .caltable,
  my-date-picker .mydp .header { font-size: 12px !important; padding: 0 !important; }
  my-date-picker .mydp .daycell { height: 23px !important; }
  my-date-picker .mydp .selector { width: auto !important; position: absolute; }
  my-date-picker .mydp td + td { border-left: none; }
  my-date-picker .mydp .daycell,
  my-date-picker .mydp .weekdaytitle { padding: 5px 3px !important; }
  my-date-picker .mydp .headerlabelbtn { font-size: 12px !important; color: var(--dark-grey) !important; min-width: 40px !important;}
  my-date-picker .mydp .icon-left,
  my-date-picker .mydp .icon-right { font-size: 12px !important; }
  my-date-picker .mydp .headertodaybtn { font-size: 12px !important; min-width: 0 !important; }
  my-date-picker .mydp .selbtngroup { top: 3px !important; right: 2px !important; height: 15px !important; }
  my-date-picker .mydp .selectorarrow::before { border-color: rgba(204, 204, 204, 0) rgba(204, 204, 204, 0) #f1f1f1 !important; border-width: 12px !important; margin-left: -12px !important; }
  my-date-picker .mydp .caltable,
  my-date-picker .mydp .daycell,
  my-date-picker .mydp .weekdaytitle { color: var(--color-a) !important; }
  my-date-picker .mydp .sunday { color: var(--color-b) !important; }

.left-icon i { margin-right: 7px; }
.right-icon i { margin-left: 7px; }

.content-component { text-align: left; display: inline-block; }
.max-content { display: inline-block; }
.main-offline { padding: 10px 20px; }

.error-message label { color: var(--color-error); }
.error-message li { color: var(--color-error); }

.bool-true { color: var(--color-c); font-size: 14px; }
.bool-false { color: var(--color-error); font-size: 14px; }

.shanoir-table table { border-collapse: collapse; }
  .shanoir-table table td, .shanoir-table table th { border: 1px solid gray; }

.overlay { position: fixed; height: 100%; width: 100%; background-color: var(--color-overlay); z-index: 100000; top: 0; bottom: 0; left: 0; right: 0; display: flex; justify-content: center; }
    .overlay .content { align-self: center; }

.detail .left { float: left; }
.detail .right { float: right; -webkit-margin-start: 2px; }

.loading { display: table; width: 100%; height: 100%;}
.loading-middle { display: table-cell; width: 100%; height: 100%; vertical-align: middle; text-align: center; }
.loading-msg { margin: 0; margin-bottom: 170px; }
.cat { width: 100%; }
    .cat img { display:inline-block; height: 100px; }

node node { margin-left: 18px; }

.link { cursor: pointer; }

.open-new-tab { text-decoration: none; }

.modal-host { background-color: var(--color-overlay); display: table; position: fixed; top: 0; bottom: 0; left: 0; right: 0; height: 100%; width: 100%; z-index: 10000; }
.modal-host .modal-cell  { display: table-cell; vertical-align: middle; text-align: center; max-height: 100vh; overflow: auto; }
.modal-host .modal-window  { display: inline-block; background-color: var(--very-light-grey); width: fit-content; border-radius: 2px; }

