.papaya {
  margin: 25px auto;
  background-color: black;
  color: var(--very-dark-black);
}
.papaya:before {
  position: relative;
  content: "Papaya requires JavaScript...";
  display: block;
  top: 45%;
  color: red;
  margin: 0 auto;
  font-size: 18px;
  font-family: sans-serif;
}
.papaya-fullscreen {
  height: 100%;
}

.papaya-menu {
  width: 220px;
  z-index: 100;
  position: absolute;
  padding: 4px;
  margin: 0;
  background-color: var(--very-light-grey);
  margin-top: 2px;
  border: 1px solid var(--grey);
}
.papaya-menu li {
  font-size: 12px;
  font-family: sans-serif;
  padding: 4px 2px;
  cursor: pointer;
  list-style-type: none;
  min-height: auto;
}
.papaya-menu li input, .papaya-menu li span {
  vertical-align: middle;
}
.papaya-menu-label {
  font-size: 14px;
  font-family: sans-serif;
  font-weight: bold;
  padding: 2px 8px;
  cursor: pointer;
  vertical-align: text-bottom;
}
.papaya-menu-titlebar {
  font-size: 16px;
  font-family: sans-serif;
  padding: 3px 8px 0 8px;
  cursor: default;
  vertical-align: text-bottom;
}
.papaya-menu-icon {
  margin-left: 5px;
}
.papaya-menu-icon img {
  box-sizing: content-box;
}
.papaya-menu-spacer {
  height: 8px;
}
.papaya-menu-unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
}
.papaya-menu-button-hovering {
  background-color: #ddd;
}
.papaya-menu-filechooser {
  cursor: pointer;
  width: 200px;
  display: inline-block;
  font-weight: normal;
}
.papaya-menu-input {
  width: 38px;
  margin-right: 5px;
}
li .papaya-menu-slider {
  vertical-align: middle;
  text-align: center;
  display: inline;
  width: 120px;
  padding: 0;
  margin: 0;
}
.papaya-dialog {
  min-width: 400px;
  max-width: 500px;
  position: absolute;
  z-index: 10000;
  font-family: sans-serif;
  box-sizing: content-box;
  background-color: var(--very-light-grey);
}
.papaya-dialog-content {
  margin: 20px;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.papaya-dialog-content-nowrap {
  white-space: nowrap;
}
.papaya-dialog-content table {
  margin: 0 auto;
}
.papaya-dialog-content-label {
  text-align: right;
  padding: 5px;
}
.papaya-dialog-content-control {
  text-align: left;
  padding: 5px;
}
.papaya-dialog-content-help {
  text-align: right;
  padding: 5px;
  font-size: 12px;
}
.papaya-dialog-title {
    font-size: 20px;
    background-color: var(--light-grey);
    color: var(--color-b);
    display: block;
    padding: 10px 15px;
    font-weight: bold;
}
.papaya-dialog-button {
  text-align: right;
  box-sizing: content-box;
  height: 22px;
  background-color: var(--light-grey);
  padding: 10px 15px;
}
.papaya-dialog-button button {
  box-sizing: content-box;
  font-size: 11px;
}
.papaya-dialog-background {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-overlay);
  width: 100%;
  height: 100%;
}
.papaya-dialog-stopscroll {
  height: 100%;
  overflow: hidden;
}
.checkForJS {
  width: 90%;
  height: 90%;
  margin: 25px auto;
  background-color: black;
}
.checkForJS:before {
  position: relative;
  content: "Papaya requires JavaScript...";
  display: block;
  top: 45%;
  color: red;
  margin: 0 auto;
  font-size: 18px;
  font-family: sans-serif;
  text-align: center;
}
.papaya-utils-unsupported {
  width: 90%;
  height: 90%;
  margin: 25px auto;
  background-color: black;
}
.papaya-utils-unsupported-message {
  position: relative;
  display: block;
  top: 45%;
  color: red;
  margin: 0 auto;
  font-size: 18px;
  font-family: sans-serif;
  text-align: center;
}
.papaya-viewer {
  line-height: 1;
  font-family: sans-serif;
}
.papaya-viewer div,
.papaya-viewer canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-family: sans-serif;
}
.papaya-viewer canvas {
  cursor: crosshair;
}
.preview button {
  display: inline-block !important;
  top: 0 !important;
  left: 0 !important;
  position: relative !important;
  opacity: 1 !important;
  background-color: var(--color-a);
}

.preview button:hover {
  opacity: 0.8 !important;
  background-color: var(--color-a);
}

.papaya-toolbar {
  position: relative;
  /* display: none !important; */
}

#File {
  display: none !important;
}

#papayaContainer0 { padding: 5px 5px 0 7px !important; border: 1px solid var(--grey); border-radius: 3px; background-color: var(--color-b-light); }